import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import ColoredSeparator from './colored-separator';
import Macaron from './macaron';
import Separator from './separator';
import TitleEffect from './title-effect';
import { extractPrice } from '../utils/price';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Products = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 36px;
  width: 1600px;
  max-width: 100%;
  @media(max-width: 768px){
    justify-content: center;
    width: 100%;
    gap: 12px;
  }
`;

const Product = styled(Link)`
  cursor: pointer;
  width: 450px;
  text-decoration: none;
  @media(max-width: 768px){
    &:first-child {
      width: 100%;
      height: auto;
    }

    &:not(first-child){
      width: calc(50% - 6px);
      height: auto;
    }
  }
`;

const ProductImage = styled.img`
  cursor: pointer;
  position: relative;
  width: 100%;
  height: auto;
`;


const Informations = styled.div`
  @media(max-width: 768px){
    padding-left: 4px; 
  }
`;

const InformationTitle = styled.div`
  color: black;
  font-size: 1.2rem;
`;



const SepratorContainer = styled.div`
  width: 100%;
`;

const ProductImageContainer = styled.div`
  position: relative;
`;

const InformationPrice = styled.div`
  color: #5F5F5F;
  display: flex;
`;

const Price = styled.div``;

const CompareAtPrice = styled.div`
  position: relative;
  margin-left: 16px;
  :before {
    content: '';
    border-bottom: 2px solid #5F5F5F;
    width: 120%;
    position: absolute;
    left: -10%;
    top: 50%;
  }
`;

const GammeSeparator = () => {
  return (
    <SepratorContainer>
      <Separator size={48} />
      <ColoredSeparator />
    </SepratorContainer>
  )
}

const Gamme = ({ title, products }) => {
  return (
    <Container>
      <TitleEffect size={2}>{title}</TitleEffect>
      <Separator size={48} />
      <Products>
        {products.map((product) => {
          // const price = Math.max(...product.variants.map(({ price }) => Number(price)));
          const to = `/products/${product.handle}`;
          const { price, compareAtPrice } = extractPrice(product);
          return (
            <Product to={to} key={product.handle}>
              <ProductImageContainer>
                <ProductImage key={product.id} src={product.images[0].originalSrc} />
                <Macaron tags={product.tags} />
              </ProductImageContainer>
              <Informations>
                <InformationTitle>{product.title}</InformationTitle>
                <InformationPrice>
                  <Price>{price}€</Price>
                  {price !== compareAtPrice ? <CompareAtPrice>{compareAtPrice}€</CompareAtPrice> : null}
                </InformationPrice>
              </Informations>
            </Product>)

        })}
        <GammeSeparator />
      </Products>

    </Container>
  )
}

export default Gamme;