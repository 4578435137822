import React from "react"
import styled from 'styled-components';
import { graphql } from "gatsby"
import Layout from "../components/layout";
import Gamme from "../components/gamme";
import Separator from "../components/separator";
import ColoredSeparator from "../components/colored-separator";
import TitleEffect from "../components/title-effect";
import Meta from "../components/meta";
import Crotchet from "../components/crotchet";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const productTypes = ["hauts", "bas", "robes", "mailles", "manteaux", "accessoires"];

const ProductsPage = ({ data }) => {
  const products = data.allShopifyProduct.edges.map(edge => edge.node);

  const orderedProducts = products.reduce((acc, product) => {
    const { productType } = product
    if (!acc[productType]) {
      acc[productType] = []
    }
    acc[productType] = [...acc[productType], product]
      .sort((p1, p2) => { // sort by date
        const pub1 = new Date(p1.publishedAt);
        const pub2 = new Date(p2.publishedAt);
        return pub2.valueOf() - pub1.valueOf()
      });
    return acc;
  }, {});

  return (
    <Layout>
      <Meta title="Boutique" />
      <ColoredSeparator />
      <TitleEffect hide>Nos Gammes</TitleEffect>
      <Separator size={48} />
      <Container>
        {
          productTypes.map((productType, index) => {
            const title = `Les ${productType[0].toUpperCase()}${productType.slice(1)}`;
            const products = orderedProducts[productType];

            return products
              ? (
                <React.Fragment key={title}>
                  <Gamme title={title} products={orderedProducts[productType]} />
                  {<Separator size={56} />}
                </React.Fragment>)
              : <React.Fragment key={title} />;
          })
        }
      </Container>
      <Crotchet />
    </Layout>
  );
}

export default ProductsPage
export const query = graphql`
  {
    allShopifyProduct(sort: { fields: [title] }) {
      edges {
        node {
          id
          title
          shopifyId
          description
          handle
          productType
          publishedAt
          priceRange {
            minVariantPrice {
              amount
            }
          }
          images {
            originalSrc
          }
          tags
          variants {
            id
            title
            price
            presentmentPrices {
              edges {
                node {
                  compareAtPrice {
                    amount
                  }
                  price {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`